/* global google */

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line

// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';
import './plugins/jquery.parallax-scroll';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      setTimeout(function () {
        if ($that.width() / width > $that.height() / height) {
          $(this).css({
            width: '100%',
            height: 'auto',
          });
        } else {
          $(this).css({
            width: ($that.height() * width) / height,
            height: '100%',
          });
        }
      }, 1000);
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Slider Certifications home
   */
  $('.certifications__slider').slick({
    arrows: true,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Slider home about
   */
  $('.home-about__slider').slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 10,
  });

  /**
   * Slider Testimonials
   */
  $('.testimonials-list__slider').slick({
    arrows: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Slider Staff page
   */
  $('.staff-other__list').slick({
    arrows: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 641,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   * Modal init
   */
  $('.js-modal').each(function () {
    let $this = $(this).data('src');
    $('.js-modal[data-src=' + $this + ']').fancybox({
      baseClass: 'fancybox-modal',
      touch: false,
      selector: '.js-modal',
      hash: false,
      autoFocus: false,
      backFocus: false,
      momentum: true,
      helpers: {
        overlay: {
          locked: true,
        },
      },
      arrows: false,
      keyboard: false,
      beforeShow: function () {
        $('.fancybox-infobar').hide();
        $('html').addClass('fancybox-active');
      },
      afterClose: function () {
        $('html').removeClass('fancybox-active');
      },
      btnTpl: {
        smallBtn:
          '<button data-fancybox-close="" class="fancybox-button fancybox-button--close fancybox-close-small" title="Close">' +
          '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 79.51 82" width="19px" height="19px"><defs><clipPath transform="translate(0 0)"><rect width="79.51" height="82" style="fill:#fff"/></clipPath></defs><path fill="#fff" d="M78.6,69.2,50.4,41,78.6,12.8a2.9,2.9,0,0,0,0-4.2L70.1.1,39.8,30.4,9.4,0,.9,8.5a2.9,2.9,0,0,0,0,4.2L29.1,40.9,1,69.2a2.9,2.9,0,0,0,0,4.2l8.5,8.5L39.8,51.6,70.2,82l8.5-8.5a3.11,3.11,0,0,0-.1-4.3" transform="translate(0 0)"/></svg>' +
          '</button>',
      },
    });
  });

  /**
   * Single service dropdown
   */
  $('.service-main__placeholder').on('click', function () {
    $('.service-main__select').toggleClass('service-main__select--opened');
  });
  $('#servicres-tabs .tabs-title > a').on('click', function () {
    if (
      $(this)
        .closest('.service-main__select')
        .hasClass('service-main__select--opened')
    ) {
      $('.service-main__placeholder > span').html($(this).html());
      $(this)
        .closest('.service-main__select')
        .removeClass('service-main__select--opened');
    }
  });

  /**
   * Slider facebook
   */
  $('.cff-posts-wrap').slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    treshold: 0.1,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            var $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Garaviy forms animate labels
   */
  function gfPlaceholder() {
    $('.gfield').each(function () {
      var str;
      var startStr = $(this).find('input, textarea, select').val();
      if (startStr) {
        $(this).find('.gfield_label').addClass('gfield_label--active');
        $(this).addClass('gfield--active');
      }
      var gfield = $(this);

      $(this)
        .find('input, textarea')
        .on('input change', function () {
          var input = $(this);
          str = input.val();
          if (str) {
            gfield.addClass('gfield--active');
            gfield.find('.gfield_label').addClass('gfield_label--active');
          } else {
            gfield.removeClass('gfield--active');
            gfield.find('.gfield_label').removeClass('gfield_label--active');
          }
        });
    });
  }

  gfPlaceholder();

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
    gfPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  var scrollFixed;
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
      $('.top-bar').toggleClass('is-active');

      if ($('.menu-icon').hasClass('is-active')) {
        scrollFixed = $(window).scrollTop();
        $('.body__inner').addClass('is-active');
      } else {
        $('.body__inner').removeClass('is-active');
        $(window).scrollTop(scrollFixed);
      }
    })
    .on('changed.zf.mediaquery', function () {
      $('.body__inner').removeClass('is-active');
      $('.menu-icon').removeClass('is-active');
      $('.top-bar').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 1025) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  /**
   * Close responsive menu on click outside
   */
  $('body').on('click', function () {
    if ($('.menu-icon').hasClass('is-active')) {
      $('.menu-icon').trigger('click');
    }
  });

  $('.top-bar, .menu-icon, .title-bar').on('click', function (event) {
    event.stopPropagation();
  });

  resizeVideo();

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */
  $('.acf-map').each(function () {
    render_map($(this));
  });

  /*
   *  This function will render a Google Map onto the selected jQuery element
   */
  function render_map($el) {
    // var
    var $markers = $el.find('.marker');
    var styles = [
      {
        featureType: 'all',
        elementType: 'geometry',
        stylers: [
          {
            color: '#63b5e5',
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [
          {
            gamma: 0.01,
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            saturation: -31,
          },
          {
            lightness: -33,
          },
          {
            weight: 2,
          },
          {
            gamma: 0.8,
          },
        ],
      },
      {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 30,
          },
          {
            saturation: 30,
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            saturation: 20,
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 20,
          },
          {
            saturation: -20,
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            lightness: 10,
          },
          {
            saturation: -30,
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
          {
            saturation: 25,
          },
          {
            lightness: 25,
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            lightness: -20,
          },
        ],
      },
    ];

    // vars
    var args = {
      zoom: 11,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      zoomControl: false,
      scrollwheel: false,
      fullscreenControl: false,
      streetViewControl: false,
      styles: styles,
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  /*
   *  This function will add a marker to the selected Google Map
   */

  var infowindow;

  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: $marker.data('marker-icon'), //uncomment if you use custom marker
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($.trim($marker.html())) {
      // create info window
      infowindow = new google.maps.InfoWindow();

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {
        // Close previously opened infowindow, fill with new content and open it
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  /*
   *  This function will center the map, showing all markers attached to this map
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
    } else {
      // fit to bounds
      map.setCenter({
        lat: bounds.getCenter().lat() + 0.07,
        lng: bounds.getCenter().lng(),
      });
    }
  }
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
